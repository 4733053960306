import { saveAs } from "../_snowpack/pkg/file-saver.js";

const writingArea = document.querySelector("#writing-area");
const showingText = document.querySelector(".text");
const downloadButton = document.querySelector(".download");
const typed = new CustomEvent("typed", { detail: {} });

let index = 0;

function stopEdits(event) {
  const key = event.key;

  // prevent backapacr or delete
  if (key === "Backspace" || key === "Delete") {
    event.preventDefault();
    error("back");
  } else if (
    // prevent native undo
    (event.ctrlKey || event.metaKey) &&
    String.fromCharCode(event.which).toLowerCase() == "z"
  ) {
    event.preventDefault();
    error("back");
  } else if (
    // prevent paste
    (event.ctrlKey || event.metaKey) &&
    String.fromCharCode(event.which).toLowerCase() == "v"
  ) {
    event.preventDefault();
    error("forward");
  } else if (key === "ArrowRight" || key === "Tab" || key === "Enter") {
    event.preventDefault();
    error("forward");
  } else if (key === "ArrowLeft") {
    event.preventDefault();
    error("back");
  } else if (key === "ArrowUp") {
    event.preventDefault();
    error("up");
  } else if (key === "ArrowDown") {
    event.preventDefault();
    error("down");
  } else if (
    key === "Shift" ||
    key === "Control" ||
    key === "Alt" ||
    key === "Meta" ||
    key === "Escape" ||
    key === "CapsLock"
  ) {
    event.preventDefault();
  } else {
    spanWrap(key);
    writingArea.dispatchEvent(typed);
    cursorBarInViewport();
  }
}

function spanWrap(key) {
  let text = key;
  const className = "char";
  index = index + 1;
  let lastChar = showingText.lastElementChild;

  let insert =
    '<span class="' +
    className +
    index +
    '" aria-hidden="true">' +
    text +
    "</span>";

  lastChar.insertAdjacentHTML("afterend", insert);
  // unwrap span
  let nextTolastChar = lastChar.previousSibling;
  nextTolastChar.outerHTML = nextTolastChar.innerHTML;
}

function stopSelect(event) {
  event.preventDefault();
  if (window.getSelection) {
    window.getSelection().removeAllRanges();
  } else if (document.selection) {
    document.selection.empty();
  }
}

function error(direction) {
  showingText.classList.add("error-" + direction);
  setTimeout(() => {
    showingText.classList.remove("error-" + direction);
  }, 300);
}

function clearPlaceholder() {
  const placeholder = document.querySelector(".placeholder");
  placeholder.remove();
  writingArea.removeEventListener("typed", clearPlaceholder);
  showingText.classList.add("typed");
}

function stopUnfocus(event) {
  const key = event.key;
  // prevent backapace or delete
  if (key === "Tab") {
    event.preventDefault();
    focusTextarea();
  }
}

function cursorBarInViewport() {
  let cursorBar = document.querySelector(".text > span:last-of-type");
  let bounding = cursorBar.getBoundingClientRect();

  if (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth) &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight)
  ) {
    return;
  } else {
    cursorBar.scrollIntoView();
  }
}

function download() {
  const content = writingArea.value;
  if (navigator.share) {
    // Web Share API is supported
    navigator
      .share({
        text: content,
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  } else {
    // Fallback download .txt file
    let blob = new Blob([content], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, "ink.txt");
  }
}

function focusTextarea() {
  document.getElementById("writing-area").focus();
}

writingArea.addEventListener("keydown", stopEdits);
writingArea.addEventListener("select", stopSelect);
writingArea.addEventListener("typed", clearPlaceholder);
window.addEventListener("keyup", stopUnfocus);
showingText.addEventListener("mouseenter", focusTextarea);
showingText.addEventListener("click", focusTextarea);
showingText.addEventListener("pointermove", focusTextarea);
downloadButton.addEventListener("click", download);

document.addEventListener("DOMContentLoaded", () => {
  focusTextarea();
});
